var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"loginPage",staticClass:"login mb-9",staticStyle:{"background":"white"},attrs:{"id":"loginPage"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":!_vm.popUp ? '8' : '12',"lg":!_vm.popUp ? '8' : '12',"xl":!_vm.popUp ? '5' : '12'}},[_c('v-card',{attrs:{"flat":_vm.popUp}},[_c('v-card-title',{class:{ 'blue white--text mb-5': !_vm.popUp, 'py-0 mb-5': _vm.popUp }},[_c('h2',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.currentPanel))+" ")])]),_c('v-card-text',[_c('MessageHandler',{attrs:{"field":"login"}}),(_vm.resendButton)?_c('div',{staticClass:"d-flex flex-row justify-center"},[_c('v-btn',{staticClass:"text-center teal white--text px-2",attrs:{"href":"/users/resendConfirmation"},on:{"click":() => {
                    _vm.$emit('ClosePopup', true);
                  }}},[_vm._v(" Resend me the confirmation email ")])],1):_vm._e(),(_vm.resendButton)?_c('v-divider',{staticClass:"pb-0 mb-0"}):_vm._e(),_c('v-list',_vm._l((_vm.oauthLogin),function(provider,providerIndex){return _c('v-list-item',{key:'provider_' + providerIndex,staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"text-left",class:provider.color,attrs:{"width":"250px","href":provider.callback + _vm.getCurrentLocation()}},[_c('v-layout',{attrs:{"width":"100%"}},[_c('v-icon',{staticClass:"mr-5",attrs:{"left":""}},[_vm._v(" "+_vm._s("fab fa-" + provider.name.toLowerCase())+" ")]),_c('v-layout',[_vm._v("with "+_vm._s(provider.name))])],1)],1)],1)}),1)],1),(_vm.currentPanel === 'login')?_c('v-card-text',[_c('v-form',{ref:"loginForm",attrs:{"id":"loginForm"},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-text-field',{attrs:{"label":"Username or email","required":"","outlined":"","rules":[_vm.rules.isRequired()]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logUser()}},model:{value:(_vm.loginData.name),callback:function ($$v) {_vm.$set(_vm.loginData, "name", $$v)},expression:"loginData.name"}}),_c('v-text-field',{attrs:{"append-icon":_vm.show1 ? 'fa-eye' : 'fa-eye-slash',"type":_vm.show1 ? 'text' : 'password',"label":"Password","counter":"","required":"","outlined":"","rules":[_vm.rules.isRequired()]},on:{"click:append":function($event){_vm.show1 = !_vm.show1},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logUser()}},model:{value:(_vm.loginData.password),callback:function ($$v) {_vm.$set(_vm.loginData, "password", $$v)},expression:"loginData.password"}}),_c('v-card-text',{staticClass:"text-center py-1"},[_c('router-link',{attrs:{"to":"/accounts/forgotPassword"}},[_c('span',{on:{"click":() => {
                        _vm.$emit('ClosePopup', true);
                      }}},[_vm._v("Forgotten your password?")])]),_c('v-divider'),_c('router-link',{attrs:{"to":"/accounts/signup"}},[_c('span',{on:{"click":() => {
                        _vm.$emit('ClosePopup', true);
                      }}},[_vm._v("Need to create a new account?")])]),_c('v-divider'),_c('a',{attrs:{"href":"https://fairsharing.gitbook.io/fairsharing/#accessing-fairsharing-through-3rd-party-accounts","target":"_blank"}},[_c('span',{on:{"click":() => {
                        _vm.$emit('ClosePopup', true);
                      }}},[_vm._v("Can't login with ORCID?")])])],1),_c('v-card-actions',{staticClass:"mt-2 justify-center"},[_c('v-btn',{staticClass:"px-4",attrs:{"light":"","color":"primary","disabled":!_vm.formValid},on:{"click":function($event){return _vm.logUser()}}},[_vm._v(" LOGIN ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }